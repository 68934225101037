import { useSelector } from "react-redux";
import { useState } from "react";

import styles from "./styles.module.scss";
import { selectBenchmarkMessageById, selectBenchmarkMessageResponseMessageId } from "../../features/benchmark-message/benchmarkMessageSlice";
import BenchmarkProgressDetail from "../BenchmarkProgressDetail";
import BenchmarkDebugModal from "../BenchmarkDebugModal";

const BenchmarkSentMessage = ({ messageId }: { messageId: string }) => {
    const message = useSelector(selectBenchmarkMessageById(messageId));
    // For sent messages, we want to get the progress of the response message
    const responseMessageId = useSelector(selectBenchmarkMessageResponseMessageId(messageId));
    const { user } = useSelector((state: any) => state.user);
    const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);

    // Default to true for now as requested
    const canDebug = user?.config?.can_debug;

    if (!message || !user) {
        return null;
    }

    return (
        <>
            <div className={`flex flex-col mb-8 px-4 pt-3 pb-3 rounded-2xl mt-4 w-4/5 ${styles.messageWrapper}`}>
                <div>
                    <div className="flex flex-row items-center">
                        <div className="bg-purple-600 w-6 h-6 rounded-full flex justify-center items-center">
                            <span className="text-white font-bold text-xxs"> {user?.first_name?.charAt(0)}{user?.last_name?.charAt(0)} </span>
                        </div>
                        <div className="ml-2">
                            <p className="font-semibold text-gray-500 text-sm">You</p>
                        </div>
                    </div>
                    <div className="w-full font-semibold text-dark-purple-800 mt-2">
                        {message.content}
                    </div>
                </div>
                <div className="mt-4">
                    <BenchmarkProgressDetail messageId={responseMessageId || ''} />
                    {responseMessageId && canDebug && (
                        <div className="mt-2">
                            <button
                                onClick={() => setIsDebugModalOpen(true)}
                                className="rounded-xl border border-gray-300 px-4 py-1 bg-brown-grey-500 text-xs font-medium text-gray-700 hover:bg-brown-grey-700 transition-colors"
                            >
                                Debug Info
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {responseMessageId && (
                <BenchmarkDebugModal
                    isOpen={isDebugModalOpen}
                    onClose={() => setIsDebugModalOpen(false)}
                    messageId={responseMessageId}
                />
            )}
        </>
    );
};

export default BenchmarkSentMessage;