import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import pulseApi from '../../services/pulse-api'; 
import { RootState } from '../../app/store';
import pulseWebsocket, { REALTIME_EVENTS } from '../../services/pulse-websocket';

interface BenchmarkChat {
    id: string,
    type: 'benchmark' | 'general',
    awaiting_response: boolean,
}

interface BenchmarkChatState {
    benchmarkChats: BenchmarkChat[]; 
}

const initialState: BenchmarkChatState = {
    benchmarkChats: [
        {
            id: `static-${Date.now()}`,
            // id: 'chat-1',
            type: 'benchmark',
            awaiting_response: false,
        }
    ],
};
export const fetchBenchmarkChats = createAsyncThunk(
    'benchmarkChat/fetchBenchmarkChats',
    async () => {
        const response = await pulseApi.get('/chat');
        return response.data;
    }
);

export const createBenchmarkChat = createAsyncThunk(
    'benchmarkChat/createBenchmarkChat',
    async (query: string) => {
        const response = await pulseApi.post('/chat', { query });
        return response.data;
    }
);

export const sendRealtimeBenchmarkChatMessage = (data: {chat_id: string, query: string, message_id: string}) => {
    pulseWebsocket.send(REALTIME_EVENTS.NEW_BENCHMARK_CHAT_MESSAGE, data);
}

export const benchmarkChatSlice = createSlice({
    name: 'benchmarkChat',
    initialState,
    reducers: { 
        registerMessageContentResponse: (state, action: PayloadAction<{message_id: string, chat_id: string}>) => {
            const benchmarkChat = state.benchmarkChats.find((chat) => chat.id === action.payload.chat_id);
            if (benchmarkChat) {
                benchmarkChat.awaiting_response = false;
            }
        },
        setAwaitingResponse: (state, action: PayloadAction<{chatId: string, isAwaiting: boolean}>) => {
            const { chatId, isAwaiting } = action.payload;
            const benchmarkChat = state.benchmarkChats.find((chat) => chat.id === chatId);
            if (benchmarkChat) {
                benchmarkChat.awaiting_response = isAwaiting;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBenchmarkChats.fulfilled, (state, action) => {
            state.benchmarkChats.push(...action.payload);
        });
    },
});

export const { setAwaitingResponse, registerMessageContentResponse } = benchmarkChatSlice.actions;

export default benchmarkChatSlice.reducer;

export const selectBenchmarkChats = (state: RootState) => state.benchmarkChat.benchmarkChats;