import { Middleware } from 'redux';
import pulseWebsocket from '../services/pulse-websocket';
import { DocumentStatus, documentProcessingStatusSlice } from '../features/document/documentProcessingStatusSlice';
import { documentSlice } from '../features/document/documentSlice';
import { workspaceMemoSlice } from '../features/workspace-memo/workspaceMemoSlice';
import { workspaceMemoSectionSlice } from '../features/workspace-memo-section/workspaceMemoSectionSlice';
import { benchmarkChatSlice } from '../features/benchmark-chat/benchmarkChatSlice';
import { benchmarkMessageSlice } from '../features/benchmark-message/benchmarkMessageSlice';
import { REALTIME_EVENTS } from '../services/pulse-websocket';

const realtimeMiddleware: Middleware = (store) => (next) => {
    pulseWebsocket.on(REALTIME_EVENTS.DOCUMENT_PROCESSING_STATUS_CHANGE, (data: DocumentStatus) => {
        store.dispatch(documentProcessingStatusSlice.actions.updateDocumentProcessingStatus(data));
        store.dispatch(documentSlice.actions.handleStatusChange(data));
    });

    pulseWebsocket.on(REALTIME_EVENTS.MEMO_DRAFT_GENERATION_STATUS_CHANGE, (data: any) => {
        store.dispatch(workspaceMemoSlice.actions.updateWorkspaceMemo(data));
    });

    pulseWebsocket.on(REALTIME_EVENTS.MEMO_SECTION_NEW_DRAFT_GENERATED, (data: any) => {
        store.dispatch(workspaceMemoSectionSlice.actions.updateWorkspaceMemoSection(data));
    });

    pulseWebsocket.on(REALTIME_EVENTS.MEMO_CONFIG_UPDATE, (data: any) => {
        store.dispatch(workspaceMemoSlice.actions.updateWorkspaceMemoConfig(data));
    });

    pulseWebsocket.on(REALTIME_EVENTS.NEW_BENCHMARK_CHAT_MESSAGE, (data: any) => {
        store.dispatch(benchmarkMessageSlice.actions.addMessage(data));
    });

    pulseWebsocket.on(REALTIME_EVENTS.BENCHMARK_CHAT_MESSAGE_PROGRESS_UPDATE, (data: any) => {
        store.dispatch(benchmarkMessageSlice.actions.updateMessageProgressData(data));
    });

    pulseWebsocket.on(REALTIME_EVENTS.BENCHMARK_CHAT_MESSAGE_CONTENT_UPDATE, (data: any) => {
        store.dispatch(benchmarkMessageSlice.actions.updateMessageContent(data));
        store.dispatch(benchmarkChatSlice.actions.registerMessageContentResponse(data));
    });

    return next;
};

export default realtimeMiddleware;
