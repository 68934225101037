import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import styles from "./styles.module.scss";
import { RootState, AppDispatch } from "../../app/store";
import { logEvent } from '../../services/amplitude';
import { addMessage } from "../../features/message/messageSlice";
import { sendQueryToChat } from "../../features/chat/chatSlice";
import MessageItem from "../MessageItem";
import ChatDocument from "../ChatDocument";

const Chat = () => {
    const dispatch: AppDispatch = useDispatch();
    const messages = useSelector((state: RootState) => state.message.messages.filter((message: any) => message.chat_id === 'chat_id_1' && message.sender === 'You'));
    const responsePending = useSelector((state: RootState) => state.chat.chats.find((chat) => chat.id === 'chat_id_1')?.responsePending);
    const user = useSelector((state: RootState) => state.user.user);
    const [queryText, setQueryText] = React.useState(''); 
    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (responsePending) {
            setQueryText('Please wait for response...');
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            setQueryText('');
        }
    }, [responsePending]);

    const handleNewUserMessage = async (event: any) => {
        if(!queryText) {
            return;
        }

        if (event.key === 'Enter' && !event.shiftKey) { 
            const timestamp = new Date().getTime();
            const id = timestamp.toString();
            event.preventDefault();
            dispatch(addMessage({
                id,
                chat_id: 'chat_id_1',
                sender: 'You',
                text: queryText,
            }));
            // Send the query to the backend
            try {
                logEvent('chat_query_sent', { query: queryText, id, sent_at: timestamp });
                await dispatch(sendQueryToChat({
                    text: queryText, 
                    chat_id: 'chat_id_1', 
                    response_to: id,
                    use_comps_chat: false
                })).unwrap();
                const completionTimestamp = new Date().getTime();
                logEvent('chat_query_sent', { id, sent_at: timestamp, completed_at: completionTimestamp});
            } catch (error) {
                logEvent('chat_query_failed', { id });
            }
          }
    };

    return (
        <div className="box-border w-full flex flex-row h-screen pb-10 overflow-hidden">
            <div className="w-full flex flex-col h-full">
                <div className="flex-1 overflow-auto pt-12">
                    <div className="flex flex-col justify-center items-center">
                        {messages.map((message: any) => (
                            <MessageItem key={message.id} id={message.id} />
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                </div>
                <div className="w-full flex justify-center h-40 pt-6">
                    <textarea 
                        value={queryText} 
                        onChange={(event) => setQueryText(event.target.value)} 
                        disabled={responsePending} 
                        onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => handleNewUserMessage(event)}
                        rows={1}
                        placeholder="Ask me anything about your docs..."
                        className={`text-center align-bottom w-3/5 h-40 resize-none rounded-lg outline-none text-xl text-stone-800 p-6 pt-4 ${styles.prompt} ${responsePending ? 'text-gray-500' : ''}`}
                    />
                </div>
            </div>
            <ChatDocument />
        </div>
    );
};

export default Chat;
