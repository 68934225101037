import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import pulseApi from '../../services/pulse-api';
import { RootState } from '../../app/store';
import { createWorkspaceDocument } from '../workspace/workspaceSlice';
import { createTemplateDocument } from '../template/templateSlice';
import { deleteWorkspace } from '../workspace/workspaceSlice';

enum DOC_TYPES {
    PDF = 'pdf',
}

export type Document = {
    id: string;
    title: string;
    type: DOC_TYPES;
    namespace_id: string;
    created_at: string;
    document_processing_status?: any;
    file_size_bytes: number;
}

type DocumentMap = {
    [documentId: string]: Document;
}

type WorkspaceDocumentsMap = {
    [workspaceId: string]: string[];
}

type TemplateDocumentsMap = {
    [templateId: string]: string[];
}

interface DocumentState {
    documentMap: DocumentMap;
    workspaceDocumentsMap: WorkspaceDocumentsMap;
    templateDocumentsMap: TemplateDocumentsMap;
}

const initialState: DocumentState = {
  documentMap: {},
  workspaceDocumentsMap: {},
  templateDocumentsMap: {},
};

export const fetchDocuments = createAsyncThunk(
  'document/fetchDocuments',
  async ({namespaceId}: {namespaceId: string}) => {
    const query = `namespace_id=${namespaceId}`;
    const data = await pulseApi.get(`/document?${query}`);
    return data;
  }
);

export const fetchWorkspaceDocuments = createAsyncThunk(
    'document/fetchWorkspaceDocuments',
    async ({namespaceId, workspaceId} : { namespaceId: string, workspaceId: string}) => {
        // const response = { data: [mockWorkspaceDoc] }; 
        const query = `namespace_id=${namespaceId}&workspace_id=${workspaceId}`;
        const data = await pulseApi.get(`/document?${query}`);
        return data;
    }
);

export const fetchTemplateDocuments = createAsyncThunk(
    'document/fetchTemplateDocuments',
    async ({namespaceId, templateId} : { namespaceId: string, templateId: string}) => {
        const query = `namespace_id=${namespaceId}&template_id=${templateId}`;
        const data = await pulseApi.get(`/document?${query}`);
        return data;
    }
);

export const downloadDocument = createAsyncThunk(
    'document/downloadDocument',
    async ({ documentId }: { documentId: string }) => {
      const response = await pulseApi.get(`/document/${documentId}/file`, { responseType: 'blob' }, { responseWithHeaders: true });

      return {
        blob: response.data,
        headers: response.headers,
      };
    }
  );

export const deleteDocument = createAsyncThunk(
    'document/deleteDocument',
    async ({documentId} : { documentId: string}) => {
        const data = await pulseApi.delete(`/document/${documentId}`);
        return data;
    }
);

export const uploadFiles = createAsyncThunk(
    'document/uploadFiles',
    async ({file} : { file: File }) => {
        const formData = new FormData();
        formData.append('files', file);
        const data = await pulseApi.post('/file', formData);
        return data;
    }
);

export const createDocuments = createAsyncThunk(
    'document/createDocument',
    async ({namespaceId, fileIds} : { namespaceId: string, fileIds: string[] }) => {
        const query = `namespace_id=${namespaceId}`;
        const data = await pulseApi.post(`/document?${query}`, {file_ids: fileIds});
        return data;
    }
);

export const createDocumentIndex = createAsyncThunk(
    'document/createDocumentIndex',
    async ({namespaceId, documentId} : { namespaceId: string, documentId: string }) => {
        const query = `namespace_id=${namespaceId}`;
        const data = await pulseApi.post(`/document/${documentId}/index?${query}`, {});
        return data;
    }
);

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    handleStatusChange: (state, action) => {
        const { id: documentId, status } = action.payload;

        if (status === "DELETE_COMPLETED") {
            state.workspaceDocumentsMap = Object.keys(state.workspaceDocumentsMap).reduce((acc: any, workspaceId) => {
                const documentIds = state.workspaceDocumentsMap[workspaceId].filter((docId) => docId !== documentId);
                acc[workspaceId] = documentIds;
                return acc;
            }, {});
    
            state.templateDocumentsMap = Object.keys(state.templateDocumentsMap).reduce((acc: any, templateId) => {
                const documentIds = state.templateDocumentsMap[templateId].filter((id) => id !== documentId);
                acc[templateId] = documentIds;
                return acc;
            }, {});
    
            delete state.documentMap[documentId];
        }
        
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        const fetchedDocuments = action.payload.documents;
        
        // Add fetched documents to state. Dedupe by document id.
        fetchedDocuments.forEach((document: Document) => {
            state.documentMap[document.id] = document;
        });
      })
        .addCase(fetchWorkspaceDocuments.fulfilled, (state, action) => {
            const {workspaceId} = action.meta.arg;
            const fetchedWorkspaceDocuments = action.payload.documents;

            // Get initial document ids for workspace
            const initialWorkspaceDocumentIds = state.workspaceDocumentsMap[workspaceId] || [];
            const currentWorkspaceDocumentIds = fetchedWorkspaceDocuments.map((document: Document) => document.id);
            state.workspaceDocumentsMap = {
                [workspaceId]: currentWorkspaceDocumentIds,
            };

            // Remove documents that are no longer associated with workspace. Add new documents.
            const removedDocumentIds = initialWorkspaceDocumentIds.filter((documentId: string) => !currentWorkspaceDocumentIds.includes(documentId));
            removedDocumentIds.forEach((documentId: string) => {
                delete state.documentMap[documentId];
            });
            fetchedWorkspaceDocuments.forEach((document: Document) => {
                state.documentMap[document.id] = document;
            });
        })
        .addCase(fetchTemplateDocuments.fulfilled, (state, action) => {
            const {templateId} = action.meta.arg;
            const fetchedTemplateDocuments = action.payload.documents;

            // Get initial document ids for template
            const initialTemplateDocumentIds = state.templateDocumentsMap[templateId] || [];
            const currentTemplateDocumentIds = fetchedTemplateDocuments.map((document: Document) => document.id);
            state.templateDocumentsMap = {
                ...state.templateDocumentsMap,
                [templateId]: currentTemplateDocumentIds,
            };

            // Remove documents that are no longer associated with template. Add new documents.
            const removedDocumentIds = initialTemplateDocumentIds.filter((documentId: string) => !currentTemplateDocumentIds.includes(documentId));
            removedDocumentIds.forEach((documentId: string) => {
                delete state.documentMap[documentId];
            });
            fetchedTemplateDocuments.forEach((document: Document) => {
                state.documentMap[document.id] = document;
            });

        })
        .addCase(createDocuments.fulfilled, (state, action) => {
            const createdDocuments = action.payload.documents;

            // Add created documents to state. Dedupe by document id.
            createdDocuments.forEach((document: Document) => {
                state.documentMap[document.id] = document;
            });
        })
        .addCase(createWorkspaceDocument.fulfilled, (state, action) => {
            const {workspace_id: workspaceId, document_id: documentId} = action.payload.workspaceDocument;

            const initialWorkspaceDocumentIds = state.workspaceDocumentsMap[workspaceId] || [];
            const currentWorkspaceDocumentIds = [...initialWorkspaceDocumentIds, documentId];
            state.workspaceDocumentsMap = {
                ...state.workspaceDocumentsMap,
                [workspaceId]: currentWorkspaceDocumentIds,
            };
        })
        .addCase(createTemplateDocument.fulfilled, (state, action) => {
            const {template_id: templateId, document_id: documentId} = action.payload.templateDocument;

            const initialTemplateDocumentIds = state.templateDocumentsMap[templateId] || [];
            const currentTemplateDocumentIds = [...initialTemplateDocumentIds, documentId];
            state.templateDocumentsMap = {
                [templateId]: currentTemplateDocumentIds,
            };
        })
        .addCase(deleteWorkspace.fulfilled, (state, action) => {
            const workspaceId = action.meta.arg.workspaceId;
            const workspaceDocuments = state.workspaceDocumentsMap[workspaceId] || [];
            workspaceDocuments.forEach((documentId: string) => {
                delete state.documentMap[documentId];
            });
            delete state.workspaceDocumentsMap[workspaceId];
        })
  },
});

const selectDocumentState = (state: RootState) => state.document;

const selectWorkspaceDocumentsMap = createSelector(
    selectDocumentState,
    (documentState) => documentState.workspaceDocumentsMap
);
const selectTemplateDocumentsMap = createSelector(
    selectDocumentState,
    (documentState) => documentState.templateDocumentsMap
);
const selectDocumentMap = createSelector(
    selectDocumentState,
    (documentState) => documentState.documentMap
);

export const selectDocumentById = (documentId: string) => createSelector(
    selectDocumentMap,
    (documentMap) => documentMap[documentId]
);

export const selectWorkspaceDocuments = (workspaceId: string) => createSelector(
    [selectWorkspaceDocumentsMap, selectDocumentMap],
    (workspaceDocumentsMap, documentMap) => {
        const documentIds = workspaceDocumentsMap[workspaceId] || [];
        const docs = documentIds.map(documentId => documentMap[documentId]);

        return docs.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    }
);

export const selectWorkspaceDocumentIds = (workspaceId: string) => createSelector(
    selectWorkspaceDocuments(workspaceId),
    (documents) => documents.map((doc: Document) => doc.id)
);

const selectTemplateDocumentTemplateId = (state: RootState, templateId: string) => templateId;
export const selectTemplateDocuments = createSelector(
    [selectDocumentState, selectTemplateDocumentTemplateId],
    (state, templateId) => {
    const documentIds = state.templateDocumentsMap[templateId] || [];
    const docs = documentIds.map(documentId => state.documentMap[documentId]);

    return docs.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
});

export const selectAllTemplateDocuments = createSelector(
    [selectDocumentState, selectTemplateDocumentsMap],
    (documentState, templateDocumentsMap) => Object.values(templateDocumentsMap).flat().map((docId: string) => documentState.documentMap[docId])
);

export const selectTemplateDocumentIds = createSelector(
    [selectDocumentState, selectTemplateDocuments],
    (document, documents) => documents.map((doc: Document) => doc.id)
);

export const selectNonTemplateDocuments = createSelector(
    [selectDocumentMap, selectAllTemplateDocuments],
    (documentMap, templateDocumentMap) => {
        const templateDocumentIds = templateDocumentMap.map((doc: Document) => doc.id);
        const docs = Object.values(documentMap).filter((document: Document) => !templateDocumentIds.includes(document.id));

        return docs.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    }
);

export const selectNonTemplateDocumentIds = createSelector(
    [selectNonTemplateDocuments],
    (documents) => documents.map((doc: Document) => doc.id)
);

export const selectDocumentTitleByDocumentId = (documentId: string) => createSelector(
    selectDocumentById(documentId),
    (document) => document?.title
);

export const selectDocumentCreationDateByDocumentId = (documentId: string) => createSelector(
    selectDocumentById(documentId),
    (document) => document?.created_at
);

export const selectDocumentFileSizeByDocumentId = (documentId: string) => createSelector(
    selectDocumentById(documentId),
    (document) => document?.file_size_bytes
);

export const selectDocumentIdsByTemplateId = (templateId: string) => createSelector(
    selectDocumentState,
    (documentState) => documentState.templateDocumentsMap[templateId] || []
);

export const selectDocumentByFilename = (filename: string) => createSelector(
    selectDocumentMap,
    (documentMap) => Object.values(documentMap).find((document: Document) => document.title === filename)
);

export default documentSlice.reducer;

