import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import TimeAgo from 'react-timeago'

import { 
    BenchmarkMessageProgressDataStage,
    selectBenchmarkMessageProgressDetailsById,
} from "../../features/benchmark-message/benchmarkMessageSlice";

const ProgressStageStatusItem = ({stage}: {stage: BenchmarkMessageProgressDataStage}) => {
    const isCompleted = stage.is_completed;
    const statusIndicatorColor = isCompleted ? 'bg-green-500' : (stage.start_time && !stage.end_time ? 'bg-orange-500' : 'bg-orange-300');
    const stageDurationSeconds = stage.end_time && stage.start_time ? (stage.end_time - stage.start_time) / 1000 : 0;
    const durationContent = isCompleted ? (
        <div className="text-gray-700 text-xxs">
            Took {stageDurationSeconds} seconds
        </div>
    ) : (
        stage.start_time && <div className="text-gray-500 text-xxs">Started <TimeAgo date={stage.start_time} /></div>
    );

    let initialTitle = '';
    let activeTitle = '';

    switch (stage.type) {
        case "Profiling":
            initialTitle = "Research and Extraction";
            activeTitle = "Researching and extracting";
            break;
        case "Filtering":
            initialTitle = "Filter Information";
            activeTitle = "Filtering information";
            break;
        case "Drafting":
            initialTitle = "Response Draft";
            activeTitle = "Drafting response";
            break;
    }

    const titleContent = isCompleted ? (
        <div className="font-semibold"> {initialTitle} </div>
    ) : (
        <div className="flex items-center gap-2">
            <div className="text-gray-500"> {stage.start_time ? activeTitle : initialTitle} </div>
            {stage.start_time && <PulseLoader size={2} color="gray" speedMultiplier={1.2} />}
        </div>
    );

    return (
        <div className="flex items-center gap-2 text-xs my-1">
            <div className={`w-2 h-2 rounded-full ${statusIndicatorColor}`} />
            {isCompleted}
            <div>
                {titleContent}
                {durationContent}
            </div>
        </div>
    );
}

const ProgressStatus = ({ messageId }: { messageId: string }) => {
    const progressData = useSelector(selectBenchmarkMessageProgressDetailsById(messageId)) || {stages: []};
    const profilingStages = progressData.stages.filter((stage) => stage.type === "Profiling");
    const otherStages = progressData.stages.filter((stage) => stage.type !== "Profiling");
    const firstProfilingStage = profilingStages[0];
    const lastProfilingStage = profilingStages[profilingStages.length - 1];
    const currentActiveProfilingStage = profilingStages.find((stage) => !stage.is_completed && stage.start_time);

    const statuses: BenchmarkMessageProgressDataStage[] = [];

    if (firstProfilingStage) {
        const startTime = firstProfilingStage.start_time;
        if(currentActiveProfilingStage){
            statuses.push({
                ...currentActiveProfilingStage,
                start_time: startTime
            });
        } else if (lastProfilingStage) {
            statuses.push({
                ...lastProfilingStage,
                start_time: startTime
            });
        }
    }

    statuses.push(...otherStages);

    const statusItems = statuses.filter((stage) => stage).map((stage, index) => {
        return <ProgressStageStatusItem key={`${messageId}-${index}`} stage={stage} />
    });
    
    return (
        <div className="my-2">
            {statusItems}
        </div>
    )
}

const BenchmarkProgressDetail = ({ messageId }: { messageId: string }) => {
    const progressData = useSelector(selectBenchmarkMessageProgressDetailsById(messageId));
    
    if (!progressData) {
        return (
            <div className="flex items-center gap-2 text-sm text-gray-500">
                Waiting for response
                <PulseLoader size={4} color="gray" speedMultiplier={1.2} />
            </div>
        )
    }

    return (
        <div>
            <div className="flex items-center gap-2 font-medium text-sm text-gray-500">Progress Status</div>
            <ProgressStatus messageId={messageId} />
        </div>
    );
};

export default BenchmarkProgressDetail;