import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { useSelector } from 'react-redux';
import { selectBenchmarkMessageProgressDetailsById } from '../../features/benchmark-message/benchmarkMessageSlice';

interface BenchmarkDebugModalProps {
    isOpen: boolean;
    onClose: () => void;
    messageId: string;
}

const BenchmarkDebugModal: React.FC<BenchmarkDebugModalProps> = ({ isOpen, onClose, messageId }) => {
    const progressData = useSelector(selectBenchmarkMessageProgressDetailsById(messageId));

    if (!progressData) {
        return null;
    }

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="debug-modal-title"
            aria-describedby="debug-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    height: '80%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '8px',
                    overflowY: 'auto',
                }}
            >
                <h2 className="text-lg font-bold mb-4">Debug Information</h2>
                {progressData.stages.map((stage, index) => {
                    const duration = stage.end_time && stage.start_time 
                        ? ((stage.end_time - stage.start_time) / 1000).toFixed(2)
                        : null;

                    return (
                        <div key={index} className="mb-6">
                            <div className="flex items-center gap-2 mb-3">
                                <div className={`w-1.5 h-1.5 rounded-full ${stage.is_completed ? 'bg-green-500' : 'bg-orange-500'}`} />
                                <h3 className="text-base font-semibold">{stage.type}</h3>
                                {duration && (
                                    <span className="text-xs text-gray-500">
                                        ({duration}s)
                                    </span>
                                )}
                            </div>

                            <div className="ml-3">
                                <div className="mb-3">
                                    <p className="text-xs text-gray-600">
                                        Start Time: {stage.start_time ? new Date(stage.start_time).toLocaleString() : 'Not started'}
                                    </p>
                                    {stage.end_time && (
                                        <p className="text-xs text-gray-600">
                                            End Time: {new Date(stage.end_time).toLocaleString()}
                                        </p>
                                    )}
                                </div>

                                {stage.debug_data && (
                                    <>
                                        <div className="mb-3">
                                            <h4 className="text-sm font-semibold mb-2">Activities</h4>
                                            <div className="bg-gray-50 p-3 rounded-lg">
                                                {stage.debug_data.activity_log.map((activity, activityIndex) => (
                                                    <div key={activityIndex} className="mb-2 last:mb-0">
                                                        <p className="text-xs font-medium">{activity.type}</p>
                                                        <p className="text-xs text-gray-600">{activity.description}</p>
                                                        <p className="text-[10px] text-gray-500">
                                                            {new Date(activity.timestamp).toLocaleString()}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div>
                                            <details className="bg-gray-50 rounded-lg">
                                                <summary className="text-sm font-semibold p-3 cursor-pointer">
                                                    Context
                                                </summary>
                                                <div className="p-3 pt-0">
                                                    <ReactMarkdown
                                                        className="text-xs text-gray-600"
                                                        remarkPlugins={[remarkGfm]}
                                                        rehypePlugins={[rehypeRaw]}
                                                    >
                                                        {stage.debug_data.context}
                                                    </ReactMarkdown>
                                                </div>
                                            </details>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    );
                })}
            </Box>
        </Modal>
    );
};

export default BenchmarkDebugModal; 