import { WebSocketClient } from '../lib/webSocketClient';
import pulseAPI from './pulse-api';

const PULSE_WEBSOCKET_URL = process.env.NODE_ENV === 'development' ? 'ws://localhost:3030/ws' : 'wss://ws.usepulse.co/ws';

export const REALTIME_EVENTS = {
    DOCUMENT_PROCESSING_STATUS_CHANGE: 'document_processing_status_change',
    MEMO_DRAFT_GENERATION_STATUS_CHANGE: 'memo_draft_generation_status_change',
    MEMO_SECTION_NEW_DRAFT_GENERATED: 'memo_section_new_draft_generated',
    MEMO_CONFIG_UPDATE: 'memo_config_update',

    NEW_BENCHMARK_CHAT_MESSAGE: 'new_benchmark_chat_message',
    BENCHMARK_CHAT_MESSAGE_PROGRESS_UPDATE: 'new_benchmark_chat_message_progress_update',
    BENCHMARK_CHAT_MESSAGE_CONTENT_UPDATE: 'new_benchmark_chat_message_content_update',
}

export class Realtime {
    private wsClient?: WebSocketClient;
    private pendingEventListeners: Map<string, ((data: any) => void)[]> = new Map();

    constructor() {
        this.connect();
    }

    public connect() {
        const token = pulseAPI.getToken();
        if (!token) {
            console.warn('Cannot connect to Pulse websockets without a token');
            return;
        }

        const onConnect = () => {
            // Reconnect to all events
            console.log(`Reconnecting to ${this.pendingEventListeners.size} event listeners`);
            this.pendingEventListeners.forEach((listeners, event) => {
                listeners.forEach(listener => {
                    this.wsClient?.on(event, listener);
                });
            });
        }
        this.wsClient = new WebSocketClient({
            url: PULSE_WEBSOCKET_URL,
            token,
            authCb: onConnect,
        });
    }

    public on(event: string, callback: (data: any) => void) {
        if (!this.wsClient) {
            console.warn('Attempted to add event listener without a websocket connection. Adding to local listeners instead');
            // Check if we have any listeners for this event
            if (!this.pendingEventListeners.has(event)) {
                this.pendingEventListeners.set(event, [callback]);
                return;
            }
            this.pendingEventListeners.get(event)?.push(callback);
            return
        }
        this.wsClient.on(event, callback);
    }

    public send(event: string, data: any) {
        if (!this.wsClient) {
            console.warn('Cannot send message without a websocket connection');
            return;
        }
        this.wsClient.send(event, data);
    }
}

export default new Realtime();
