import { useSelector } from "react-redux";

import Chat from "../components/Chat";
import BenchmarkChat from "../components/BenchmarkChat";
import { selectUser } from "../features/user/userSlice";

const Query = () => {
    const user = useSelector(selectUser);
    const isBenchmark = user?.config?.should_use_comps_chat;

    return (
        <div className="w-full flex flex-col">
            <h3 className="pb-4 text-xl font-medium"></h3>
            {isBenchmark ? <BenchmarkChat /> : <Chat />}
        </div>
    );
};

export default Query;