import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Pinecone } from '@pinecone-database/pinecone';
import OpenAI from 'openai';

import pulseApi from '../../services/pulse-api'; 

interface ChatState {
  chats: {id: string, responsePending: boolean}[];
}

const initialState: ChatState = {
  chats: [
    {id: 'chat_id_1', responsePending: false},
  ],
};

interface QueryPayload {
  text: string;
  chat_id: string;
  response_to: string;
  use_comps_chat: boolean;
}

export const sendQueryToChat = createAsyncThunk<any,QueryPayload>(
  'chat/sendQueryToChat',
  async (param: {text: string, chat_id: string, response_to: string, use_comps_chat?: boolean}) => {
    const path = param.use_comps_chat ? '/chat/default/benchmark' : '/chat/default/message';
    const data = await pulseApi.post(path, {
      text: param.text,
    });
    
    return data;
  }
);

export const ChatSlice = createSlice({
  name: 'Chat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendQueryToChat.pending, (state, action) => {
      const chat = state.chats.find(chat => chat.id === action.meta.arg.chat_id);
      if (chat) {
        chat.responsePending = true;
      }
    });
    builder.addCase(sendQueryToChat.fulfilled, (state, action) => {
      const chat = state.chats.find(chat => chat.id === action.meta.arg.chat_id);
      if (chat) {
        chat.responsePending = false;
      }
    });
  }
});

export default ChatSlice.reducer;
