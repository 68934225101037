import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { createSelector } from 'reselect';

import pulseApi from '../../services/pulse-api';
import { RootState } from '../../app/store';
import { extractCitationMap, Citation } from './utils';

export interface BenchmarkMessageProgressData {
    stages: BenchmarkMessageProgressDataStage[],
}

export interface BenchmarkMessageProgressDataStage {
    type: 'Profiling' | 'Filtering' | 'Drafting',
    start_time?: number,
    end_time?: number,
    is_completed: boolean,
    debug_data?: {
        context: string,
        activity_log: {
            type: string,
            description: string,
            timestamp: number,
        }[],
    },
}

interface BenchmarkMessage {
    id: string,
    chat_id: string,
    parent_message_id?: string,
    sender_type: 'you' | 'pulse',
    content: string,
    timestamp: number,
    progress_data?: BenchmarkMessageProgressData;
}

interface BenchmarkMessageState {
    benchmarkMessagesMap: {
        [messageId: string]: BenchmarkMessage;
    };
    chatBenchmarkMessagesMap: {
        [chatId: string]: string[];
    };
    messageContentCitationMap: {
        [messageId: string]: {
            citationMap: Record<string, Citation>,
            renumberedCitationMap: Record<string, number>,
        };
    };
}

// const mockMessageId = 'server-message-1';
// const mockMessageContent = `
// Revenue Comparison: Meta Platforms, Inc. vs. Block

// Meta Platforms, Inc. Revenue Performance
// | Period             | Revenue (in billions) | Year-over-Year Growth |
// | ------------------ | --------------------- | --------------------- |
// | Q4 2023            | $40.11                | -                     |
// | Q4 2024            | $48.39                | 21% [cite-13gg-1][cite-says-2][cite-8zkf-3] |
// | Full Year 2023     | $134.90               | -                     |
// | Full Year 2024     | $164.50               | 22% [cite-13gg-1][cite-says-2][cite-8zkf-3] |

// Block Revenue Performance
// | Period                     | Revenue (in millions) | Year-over-Year Growth |
// | -------------------------- | --------------------- | --------------------- |
// | Three Months Ended Sep 30, 2024 | $5,975.80             | 15.5% [cite-m0lh-1][cite-4qtd-2] |
// | Nine Months Ended Sep 30, 2024  | $18,088.49            | 25.9% [cite-m0lh-1][cite-4qtd-2] |

// Sources
// [cite-13gg-1] bettercharge+deck+march-2024.pdf, Page 1 (Transforming)
// [cite-says-2] bettercharge+deck+march-2024.pdf, Page 10. (Card)
// [cite-8zkf-3] bettercharge+deck+march-2024.pdf, Page 9.
// [cite-m0lh-1] bettercharge+deck+march-2024.pdf, Page 5.
// [cite-4qtd-2] bettercharge+deck+march-2024.pdf, Page 14.
// `;

// const mockCitationMapData = extractCitationMap(mockMessageContent);
// console.log(mockCitationMapData);

// const initialState: BenchmarkMessageState = {
//     benchmarkMessagesMap: {
//         [mockMessageId]: {
//             id: mockMessageId,
//             chat_id: 'chat-1',
//             sender_type: 'pulse',
//             content: mockMessageContent,
//             timestamp: 1715452800,
//         },
//     },
//     chatBenchmarkMessagesMap: {
//         'chat-1': [mockMessageId],
//     },
//     messageContentCitationMap: {
//         [mockMessageId]: mockCitationMapData,
//     },
// };

const initialState: BenchmarkMessageState = {
    benchmarkMessagesMap: {},
    chatBenchmarkMessagesMap: {},
    messageContentCitationMap: {},
};

export const fetchBenchmarkMessages = createAsyncThunk(
    'benchmarkMessages/fetchBenchmarkMessages',
    async (chatId: string) => {
        const response = await pulseApi.get(`chat/${chatId}/messages`);
        return response.data;
    }
);

export const benchmarkMessageSlice = createSlice({
    name: 'benchmarkMessages',
    initialState,
    reducers: {
        addMessage: (state, action: PayloadAction<BenchmarkMessage>) => {
            state.benchmarkMessagesMap[action.payload.id] = action.payload;
            if (!state.chatBenchmarkMessagesMap[action.payload.chat_id]) {
                state.chatBenchmarkMessagesMap[action.payload.chat_id] = [];
            }
            state.chatBenchmarkMessagesMap[action.payload.chat_id].push(action.payload.id);
        },
        updateMessageProgressData: (state, action: PayloadAction<{ message_id: string, progress_data: BenchmarkMessageProgressData }>) => {
            if (state.benchmarkMessagesMap[action.payload.message_id]) {
                state.benchmarkMessagesMap[action.payload.message_id].progress_data = action.payload.progress_data;
            }
        },
        updateMessageContent: (state, action: PayloadAction<{ message_id: string, content: string }>) => {
            if (state.benchmarkMessagesMap[action.payload.message_id]) {
                console.log('Message content: ', action.payload.content);
                state.benchmarkMessagesMap[action.payload.message_id].content = action.payload.content;
            }
            const citationMap = extractCitationMap(action.payload.content);
            state.messageContentCitationMap[action.payload.message_id] = citationMap;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBenchmarkMessages.fulfilled, (state, action) => {
            const messages = action.payload as BenchmarkMessage[];
            messages.forEach((message) => {
                state.benchmarkMessagesMap[message.id] = message;
                if (!state.chatBenchmarkMessagesMap[message.chat_id]) {
                    state.chatBenchmarkMessagesMap[message.chat_id] = [];
                }
                state.chatBenchmarkMessagesMap[message.chat_id].push(message.id);
            });
        });
    },
});

export const { addMessage, updateMessageProgressData, updateMessageContent } = benchmarkMessageSlice.actions;

export default benchmarkMessageSlice.reducer;

const selectBenchmarkMessageState = (state: RootState) => state.benchmarkMessage;

const selectBenchmarkMessagesMap = createSelector(
    selectBenchmarkMessageState,
    (state) => state.benchmarkMessagesMap
);

const selectChatBenchmarkMessagesMap = createSelector(
    selectBenchmarkMessageState,
    (state) => state.chatBenchmarkMessagesMap
);

export const selectBenchmarkMessagesByChatId = (chatId: string) => createSelector(
    [selectBenchmarkMessagesMap, selectChatBenchmarkMessagesMap],
    (benchmarkMessagesMap, chatBenchmarkMessagesMap) => chatBenchmarkMessagesMap[chatId] ? chatBenchmarkMessagesMap[chatId].map((messageId: string) => benchmarkMessagesMap[messageId]) : [],
);

export const selectSortedBenchmarkMessagesById = (chatId: string) => createSelector(
    [selectBenchmarkMessagesMap, selectChatBenchmarkMessagesMap],
    (benchmarkMessagesMap, chatBenchmarkMessagesMap) => {
        const messages = chatBenchmarkMessagesMap[chatId] ? chatBenchmarkMessagesMap[chatId].map((messageId: string) => benchmarkMessagesMap[messageId]) : [];
        return messages.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
    }
);

export const selectBenchmarkMessageById = (messageId: string) => createSelector(
    selectBenchmarkMessagesMap,
    (benchmarkMessagesMap) => benchmarkMessagesMap[messageId]
);

export const selectBenchmarkMessageResponseMessageId = (messageId: string) => createSelector(
    selectBenchmarkMessagesMap,
    (benchmarkMessagesMap): string | undefined => {
        const responseMessage = Object.values(benchmarkMessagesMap).find((message) => message.parent_message_id === messageId);
        return responseMessage?.id;
    }
);

export const selectBenchmarkMessageProgressDetailsById = (messageId: string) => createSelector(
    selectBenchmarkMessagesMap,
    selectBenchmarkMessageById(messageId),
    (_, message) => {
        if (!message) {
            return null;
        }
        return message.progress_data;
    }
);

export const selectMessageContentCitationMapById = (messageId: string) => createSelector(
    selectBenchmarkMessageState,
    (state) => state.messageContentCitationMap[messageId]
);