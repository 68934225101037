import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../../app/store";
import { logEvent } from "../../services/amplitude";
import { selectBenchmarkChats } from "../../features/benchmark-chat/benchmarkChatSlice";
import { selectSortedBenchmarkMessagesById } from "../../features/benchmark-message/benchmarkMessageSlice";
import BenchmarkReceivedMessage from "../BenchmarkReceivedMessage";
import BenchmarkSentMessage from "../BenchmarkSentMessage";
import ChatDocument from "../ChatDocument";
import styles from "./styles.module.scss";
import { sendRealtimeBenchmarkChatMessage, setAwaitingResponse, registerMessageContentResponse } from "../../features/benchmark-chat/benchmarkChatSlice";
import { addMessage, updateMessageProgressData, updateMessageContent } from "../../features/benchmark-message/benchmarkMessageSlice";

const BenchmarkChat = () => {
    const dispatch = useDispatch();
    const mainChat = useSelector(selectBenchmarkChats)[0];
    const chatMessages = useSelector(selectSortedBenchmarkMessagesById(mainChat.id));
    const lastSystemMessage = chatMessages.find((message) => message.sender_type === 'pulse');
    const [queryText, setQueryText] = useState('');
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const responsePending = mainChat.awaiting_response;

    useEffect(() => {
        if (responsePending) {
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            setQueryText('');
        }
    }, [responsePending]);

    if (!mainChat) {
        return null;
    }

    const handleNewUserMessage = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if(!queryText) {
            return;
        }

        if (event.key === 'Enter' && !event.shiftKey) {
            if (responsePending) {
                return;
            }
            const timestamp = new Date().getTime();
            const messageId = `message-${timestamp.toString()}`;
            event.preventDefault();

            dispatch(setAwaitingResponse({ chatId: mainChat.id, isAwaiting: true }));
            const messageToSend = {
                id: messageId,
                chat_id: mainChat.id,
                sender_type: 'you' as const,
                content: queryText,
                timestamp,
                parent_message_id: lastSystemMessage?.id || '',
            }
            dispatch(addMessage(messageToSend));
            
            try {
                sendRealtimeBenchmarkChatMessage({chat_id: mainChat.id, query: queryText, message_id: messageId});
                setQueryText('');
            } catch (error) {
                logEvent('chat_query_failed', { query: queryText });
            }
        }
        
    };
    
    const messageList = chatMessages.map((message) => {
        if (message.sender_type === 'you') {
            return <BenchmarkSentMessage key={message.id} messageId={message.id} />;
        } else {
            return <BenchmarkReceivedMessage key={message.id} messageId={message.id} />;
        }
    });

    return (
        <div className="box-border w-full flex flex-row h-screen pb-10 overflow-hidden">
            <div className="w-full flex flex-col h-full">
                <div className="flex-1 overflow-auto pt-12">
                    <div className="flex flex-col justify-center items-center">
                        {messageList}
                        <div ref={messagesEndRef} />
                    </div>
                </div>
                <div className="w-full flex justify-center h-40 pt-6">
                    <textarea 
                        value={queryText} 
                        onChange={(event) => { setQueryText(event.target.value)} }
                        onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => handleNewUserMessage(event)}
                        rows={1}
                        placeholder="Enter your request here..."
                        className={`text-center align-bottom w-3/5 h-40 resize-none rounded-lg outline-none text-xl text-stone-800 p-6 pt-4 ${styles.prompt} ${responsePending ? 'text-gray-500' : ''}`}
                    />
                </div>
            </div>
            <ChatDocument />
        </div>
    );
};

export default BenchmarkChat;