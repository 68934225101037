import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import CitationHoverPreview from "../CitationHoverPreview";
import {
  selectBenchmarkMessageById,
  selectMessageContentCitationMapById,
} from "../../features/benchmark-message/benchmarkMessageSlice";

const BenchmarkReceivedMessage = ({ messageId }: { messageId: string }) => {
  const message = useSelector(selectBenchmarkMessageById(messageId));
  const citationData = useSelector(selectMessageContentCitationMapById(messageId));

  if (!message || !citationData) return null;

  let content = message.content;

  // Replace citation references with a span that includes custom data attributes.
  const citationRegex = /\[(cite-[\w-]+)\]/g;
  content = content.replace(citationRegex, (match, citationId) => {
    return `<span data-citation-id="${citationId}" data-message-id="${messageId}"></span>`;
  });

  // Remove the Sources section completely.
  const sourcesRegex = /Sources:?\s*(\n|\r\n)*?([\s\S]*)/i;
  content = content.replace(sourcesRegex, "").trim();

  // Remove trailing asterisks at the end of the content (at the bottom of the message)
  content = content.replace(/^\*\*\s*$/m, "");

  return (
    <div className="flex flex-col mb-8 px-4 rounded-2xl w-4/5">
      <div className="mt-2">
        <div className="flex flex-row items-center">
          <div className="bg-dark-purple-800 w-6 h-6 rounded-full flex justify-center items-center">
            <span className="text-white font-bold text-xxs"> P </span>
          </div>
          <div className="ml-2">
            <p className="font-semibold text-gray-500 text-sm">Pulse</p>
          </div>
        </div>
        <div className="w-full">
          <div className="text-md w-full lg:prose-sm">
            <ReactMarkdown
              className="w-full markdown"
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
              components={{
                // Override the span element.
                span: ({ node, ...props }) => {
                  const citationId = node?.properties?.["dataCitationId"];
                  const messageId = node?.properties?.["dataMessageId"];
                  if (typeof citationId === 'string' && typeof messageId === 'string') {
                    return (
                      <CitationHoverPreview
                        messageId={messageId}
                        citationId={citationId}
                      />
                    );
                  }
                  return <span {...props} />;
                },
              }}
            >
              {content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenchmarkReceivedMessage;
