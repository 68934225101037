export interface Citation {
  citationTextLine: string;
  filename: string;
  pageNumber: number;
  selectedText?: string;
  textPosition?: {
    boundingBox?: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
  };
}

export function extractCitationMap(markdownContent: string): { 
  citationMap: Record<string, Citation>, 
  renumberedCitationMap: Record<string, number> 
} {
    const citationRegex = /^-?\s*\[(cite-[\w-]+)\]\s+([^,]+),\s*[Pp]age\s*(\d+)(?:\s*\((.*?)\))?\.?/gm;
    const citationMap: Record<string, Citation> = {};

    let match: RegExpExecArray | null;

    while ((match = citationRegex.exec(markdownContent)) !== null) {
        const key = match[1]; // Extract citation key (e.g., "cite-13gg-1")
        const filename = match[2].trim(); // Extract filename
        const pageNumber = parseInt(match[3].trim(), 10); // Extract page number
        const citationTextLine = match[0].trim(); // Store the full line as reference
        const selectedText = match[4]?.trim(); // Extract selected text if available

        citationMap[key] = { 
          citationTextLine, 
          filename, 
          pageNumber,
          selectedText,
          textPosition: undefined // This will be populated when the PDF is loaded
        };
    }

    // Step 1: Assign new numbering (1 to N)
    const citationKeys = Object.keys(citationMap);
    const newNumbering: Record<string, number> = {};

    citationKeys.forEach((key, index) => {
        newNumbering[key] = index + 1;
    });

    return {
        citationMap,
        renumberedCitationMap: newNumbering,
    };
}