import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useMemo } from "react";
import { createPortal } from 'react-dom';
import { selectMessageContentCitationMapById } from "../../features/benchmark-message/benchmarkMessageSlice";
import { downloadDocument, selectDocumentByFilename } from "../../features/document/documentSlice";
import { AppDispatch, RootState } from "../../app/store";
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { fileTypeFromBlob } from 'file-type-browser';
import { pdfjs } from 'react-pdf';
import { Citation } from '../../features/benchmark-message/utils';

// Worker configuration moved to src/pdfjs-worker.js

// Cache interface for storing file data
interface FileCache {
  [key: string]: {
    blob: Blob;
    contentType: string;
    timestamp: number;
    url?: string;
  };
}

// Create a shared cache that persists across component instances
const globalFileCache: FileCache = {};
const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes in milliseconds

interface CitationHoverPreviewProps {
  messageId: string;
  citationId: string;
}

const CitationHoverPreview = ({ messageId, citationId }: CitationHoverPreviewProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const citationData = useSelector(selectMessageContentCitationMapById(messageId));
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [pdfData, setPdfData] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [pdfError, setPdfError] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [useFallbackPreview, setUseFallbackPreview] = useState(false);
  const [popupPosition, setPopupPosition] = useState<null | { top: number; left: number }>(null);
  const citationRef = useRef<HTMLSpanElement>(null);
  const [pageScale, setPageScale] = useState(1);

  const { citationMap, renumberedCitationMap } = citationData || { citationMap: {}, renumberedCitationMap: {} };
  const newCitationNumber = renumberedCitationMap[citationId];
  const citationDetails = citationMap[citationId];

  const document = useSelector(selectDocumentByFilename(citationDetails?.filename));

  // Memoize PDF options to prevent unnecessary re-renders
  const pdfOptions = useMemo(() => ({
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
    cMapPacked: true,
  }), []);

  // Use a ref to find the PDF page
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  // Update popup position when citation is hovered or window is resized
  useEffect(() => {
    const updatePosition = () => {
      if (citationRef.current && isHovered) {
        if (isExpanded) {
          // When expanded, center in viewport
          setPopupPosition({
            top: window.innerHeight * 0.1, // 10% from top
            left: window.innerWidth * 0.5, // center horizontally
          });
        } else {
          // Normal position relative to citation
          const rect = citationRef.current.getBoundingClientRect();
          const scrollY = window.scrollY;
          const scrollX = window.scrollX;
          
          setPopupPosition({
            top: rect.bottom + scrollY,
            left: rect.left + (rect.width / 2) + scrollX,
          });
        }
      } else {
        setPopupPosition(null);
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [isHovered, isExpanded]);

  // Function to detect file type
  const detectFileType = async (blob: Blob) => {
    try {
      const fileType = await fileTypeFromBlob(blob);
      return fileType?.mime || '';
    } catch (error) {
      console.error('Error detecting file type:', error);
      return '';
    }
  };

  // Function to check if cache entry is valid
  const isCacheValid = (cacheEntry: FileCache[string]) => {
    return cacheEntry && (Date.now() - cacheEntry.timestamp) < CACHE_EXPIRY;
  };

  // Function to add file to cache
  const addToCache = (documentId: string, blob: Blob, contentType: string) => {
    globalFileCache[documentId] = {
      blob,
      contentType,
      timestamp: Date.now(),
    };
  };

  // Function to get file from cache
  const getFromCache = (documentId: string) => {
    const cacheEntry = globalFileCache[documentId];
    if (cacheEntry && isCacheValid(cacheEntry)) {
      // If URL was revoked, create a new one
      if (cacheEntry.contentType.includes('pdf') && (!cacheEntry.url || !isValidObjectURL(cacheEntry.url))) {
        cacheEntry.url = URL.createObjectURL(cacheEntry.blob);
      }
      return cacheEntry;
    }
    // Clean up expired cache entry
    if (cacheEntry) {
      if (cacheEntry.url) {
        URL.revokeObjectURL(cacheEntry.url);
      }
      delete globalFileCache[documentId];
    }
    return null;
  };

  // Function to check if an object URL is still valid
  const isValidObjectURL = (url: string) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open('HEAD', url, false); // Synchronous request
      xhr.send();
      return xhr.status !== 0;
    } catch {
      return false;
    }
  };

  // Load file content when hovering
  useEffect(() => {
    if (isHovered && document && !fileContent && !pdfData && !isLoading) {
      setIsLoading(true);
      setPdfError(null);
      setUseFallbackPreview(false);

      // Check cache first
      const cachedFile = getFromCache(document.id);
      if (cachedFile) {
        const isPdfFile = cachedFile.contentType.includes('pdf');
        setIsPdf(isPdfFile);

        if (isPdfFile) {
          try {
            // Reuse cached URL if available, otherwise create new one
            const url = cachedFile.url || URL.createObjectURL(cachedFile.blob);
            if (!cachedFile.url) {
              globalFileCache[document.id].url = url;
            }
            setPdfData(url);
          } catch (error) {
            console.error('Error creating PDF URL from cache:', error);
            setPdfError('Failed to load PDF preview');
            setUseFallbackPreview(true);
          }
        } else {
          // Handle text content from cache
          try {
            const reader = new FileReader();
            reader.onload = () => {
              try {
                const content = reader.result as string;
                const lines = content.split('\n');
                const pageLines = lines.slice(
                  Math.max(0, (citationDetails.pageNumber - 1) * 20),
                  (citationDetails.pageNumber - 1) * 20 + 10
                );
                setFileContent(pageLines.join('\n'));
              } catch (e) {
                console.error('Error processing cached file content:', e);
                setFileContent('Unable to preview this file type');
              }
              setIsLoading(false);
            };
            reader.onerror = () => {
              setFileContent('Unable to preview this file type');
              setIsLoading(false);
            };
            reader.readAsText(cachedFile.blob);
          } catch (error) {
            console.error('Error reading cached file:', error);
            setFileContent('Unable to preview this file type');
            setIsLoading(false);
          }
        }
        setIsLoading(false);
        return;
      }

      // If not in cache, download and cache the file
      dispatch(downloadDocument({ documentId: document.id }))
        .unwrap()
        .then(async ({ blob, headers }) => {
          const contentType = headers['content-type'] || '';
          const fileExtension = document.title.toLowerCase().split('.').pop() || '';
          let detectedMimeType = '';
          
          try {
            detectedMimeType = await detectFileType(blob);
          } catch (error) {
            console.error('Error detecting file type:', error);
          }
          
          const isPdfFile = 
            contentType.includes('pdf') || 
            fileExtension === 'pdf' || 
            detectedMimeType.includes('pdf');
          
          // Add to cache
          addToCache(document.id, blob, isPdfFile ? 'application/pdf' : 'text/plain');
          
          setIsPdf(isPdfFile);
          
          if (isPdfFile) {
            try {
              const url = URL.createObjectURL(blob);
              globalFileCache[document.id].url = url;
              setPdfData(url);
            } catch (error) {
              console.error('Error creating PDF URL:', error);
              setPdfError('Failed to load PDF preview');
              setUseFallbackPreview(true);
            }
          } else {
            try {
              const reader = new FileReader();
              reader.onload = () => {
                try {
                  const content = reader.result as string;
                  const lines = content.split('\n');
                  const pageLines = lines.slice(
                    Math.max(0, (citationDetails.pageNumber - 1) * 20),
                    (citationDetails.pageNumber - 1) * 20 + 10
                  );
                  setFileContent(pageLines.join('\n'));
                } catch (e) {
                  console.error('Error processing file content:', e);
                  setFileContent('Unable to preview this file type');
                }
                setIsLoading(false);
              };
              reader.onerror = () => {
                setFileContent('Unable to preview this file type');
                setIsLoading(false);
              };
              reader.readAsText(blob);
            } catch (error) {
              console.error('Error reading file:', error);
              setFileContent('Unable to preview this file type');
              setIsLoading(false);
            }
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error loading file content:', error);
          setIsLoading(false);
          setPdfError('Failed to load file');
        });
    }
  }, [isHovered, document, fileContent, pdfData, isLoading, dispatch, citationDetails]);

  // Clean up when component unmounts
  useEffect(() => {
    return () => {
      // Only clean up component-specific URLs, not cached ones
      if (pdfData && !document?.id) {
        URL.revokeObjectURL(pdfData);
      }
    };
  }, [pdfData, document]);

  if (!newCitationNumber || !citationDetails) return <span>[?]</span>;

  if (!citationData) return null;

  const handlePdfLoadError = (error: Error) => {
    console.error('PDF load error occurred', error);
    // Don't set error state for transport destroyed errors as they're expected during component unmounting
    if (error && error.message && error.message.includes('Transport destroyed')) {
      console.log('Ignoring expected transport destroyed error during unmount');
      return;
    }
    setPdfError('Failed to load PDF preview');
    setUseFallbackPreview(true);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPdfError(null);
  };

  const handlePageRenderSuccess = (page: any) => {
    setPageScale(page.scale || 1);
  };

  // Fallback preview for when PDF.js fails
  const renderFallbackPreview = () => (
    <div className="text-xs bg-gray-100 p-2 rounded">
      <div className="font-medium mb-1">PDF Preview Unavailable</div>
      <div className="text-xs text-gray-600">
        The PDF preview could not be loaded. This may be a PDF format issue or a browser limitation.
      </div>
      <div className="mt-2 text-xs text-blue-500 cursor-pointer" 
           onClick={(e) => {
             e.stopPropagation();
             if (pdfData) {
               const link = window.document.createElement('a');
               link.href = pdfData;
               link.download = citationDetails.filename;
               window.document.body.appendChild(link);
               link.click();
               window.document.body.removeChild(link);
             }
           }}>
        Download PDF
      </div>
    </div>
  );

  return (
    <span
      ref={citationRef}
      onMouseEnter={() => {
        const rect = citationRef.current?.getBoundingClientRect();
        if (rect) {
          setPopupPosition({
            top: rect.bottom + window.scrollY,
            left: rect.left + (rect.width / 2) + window.scrollX,
          });
        }
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        setIsExpanded(false);
        // Don't revoke cached URLs
        if (pdfData && !document?.id) {
          URL.revokeObjectURL(pdfData);
        }
        setPdfData(null);
        setFileContent(null);
        setIsPdf(false);
        setPdfError(null);
        setNumPages(null);
        setUseFallbackPreview(false);
      }}
      className="relative cursor-pointer text-blue-500 hover:underline"
    >
      [{newCitationNumber}]
      {isHovered && popupPosition && typeof window !== 'undefined' && window.document?.body && createPortal(
        <div 
          className={`citation-preview fixed bg-white text-gray-800 border rounded-lg p-2 shadow-lg text-sm ${
            isExpanded ? 'w-[80vw] h-[80vh] overflow-hidden' : 'w-96'
          } z-[9999]`}
          style={{
            top: popupPosition.top + 'px',
            left: popupPosition.left + 'px',
            transform: 'translateX(-50%)',
            transition: isExpanded ? 'all 0.2s ease-in-out' : 'none'
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => {
            setIsHovered(false);
            setIsExpanded(false);
            // Don't revoke cached URLs
            if (pdfData && !document?.id) {
              URL.revokeObjectURL(pdfData);
            }
            setPdfData(null);
            setFileContent(null);
            setIsPdf(false);
            setPdfError(null);
            setNumPages(null);
            setUseFallbackPreview(false);
          }}
        >
          <div className="flex justify-between items-center mb-1">
            <div className="font-semibold">{citationDetails.filename}</div>
            <div className="flex gap-2">
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  if (pdfData) {
                    const link = window.document.createElement('a');
                    link.href = pdfData;
                    link.download = citationDetails.filename;
                    window.document.body.appendChild(link);
                    link.click();
                    window.document.body.removeChild(link);
                  } else if (document) {
                    // If no PDF data is available yet, trigger the download
                    dispatch(downloadDocument({ documentId: document.id }))
                      .unwrap()
                      .then(({ blob }) => {
                        const url = URL.createObjectURL(blob);
                        const link = window.document.createElement('a');
                        link.href = url;
                        link.download = citationDetails.filename;
                        window.document.body.appendChild(link);
                        link.click();
                        window.document.body.removeChild(link);
                        URL.revokeObjectURL(url);
                      })
                      .catch(error => {
                        console.error('Error downloading file:', error);
                      });
                  }
                }}
                className="text-gray-500 hover:text-gray-700 p-1 rounded hover:bg-gray-100"
                title="Download file"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
              </button>
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(!isExpanded);
                }}
                className="text-gray-500 hover:text-gray-700 p-1 rounded hover:bg-gray-100"
                title={isExpanded ? "Collapse" : "Expand"}
              >
                {isExpanded ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div className="text-xs text-gray-600 mb-1">
            Page {citationDetails.pageNumber}
            {numPages && <span> of {numPages}</span>}
          </div>
          {isLoading ? (
            <div className="text-xs italic">Loading preview...</div>
          ) : document ? (
            isPdf && pdfData ? (
              useFallbackPreview || pdfError ? (
                renderFallbackPreview()
              ) : (
                <div 
                  ref={pdfContainerRef} 
                  className={`text-xs bg-gray-100 p-1 rounded overflow-y-auto relative ${
                    isExpanded ? 'h-[calc(80vh-6rem)]' : 'max-h-72'
                  }`}
                >
                  <Document 
                    file={pdfData} 
                    loading={<div className="text-xs italic">Loading PDF...</div>}
                    onLoadError={handlePdfLoadError}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={pdfOptions}
                  >
                    <div className="relative">
                      <Page 
                        pageNumber={Math.min(citationDetails.pageNumber, numPages || 1)} 
                        width={isExpanded ? Math.min(window.innerWidth * 0.75, window.innerHeight * 1.1) : 336}
                        renderTextLayer={true}
                        renderAnnotationLayer={false}
                        onRenderSuccess={handlePageRenderSuccess}
                        error={<div className="text-xs text-red-500">Error loading page {citationDetails.pageNumber}</div>}
                        onRenderError={() => setUseFallbackPreview(true)}
                      />
                    </div>
                  </Document>
                </div>
              )
            ) : fileContent ? (
              <div className="text-xs font-mono bg-gray-100 p-1 rounded max-h-40 overflow-y-auto whitespace-pre-wrap">
                {fileContent}
                {citationDetails.selectedText && (
                  <div className="mt-2 bg-yellow-50 p-2 rounded border border-yellow-200">
                    <div className="font-medium">Referenced Text:</div>
                    <div className="italic">{citationDetails.selectedText}</div>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-xs italic">Hover to load preview</div>
            )
          ) : (
            <div className="text-xs italic">File not found in document library</div>
          )}
        </div>,
        window.document.body
      )}
    </span>
  );
};

export default CitationHoverPreview;
